.menu-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.menu-item {
    font-size: 14px;
    font-weight: 500;
    color: #363636;
    cursor: pointer;
    padding: 10px 0px;
    position: relative;
    transition: all 0.3s ease;
    text-align: left;
    border-bottom: 1px solid #e4e4e4;
}

.menu-item.active {
    color: #04B7B1;
}

.menu-item.active::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 3px;
    background-color: #04B7B1;
    border-radius: 5px;
    transition: all 0.3s ease;
}

.menu-item:hover {
    color: #18b9b4;
}
