body {
  margin: 0;
  font-family: "Urbanist", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html{
  font-family: "Urbanist", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-checkbox .ant-checkbox-inner {
  width: var(--width);
  height: var(--height);
  background-color: var(--background-color);
  border-color: var(--border-color);
}

.ant-checkbox-disabled .ant-checkbox-inner {
  width: var(--width);
  height: var(--height);
  background-color: var(--disabled-background-color);
  border-color: var(--disabled-border-color);
}

.ant-checkbox-checked .ant-checkbox-inner {
  width: var(--width);
  height: var(--height);
  background-color: var(--inner-background-color);
  border-color: var(--inner-background-color);
}

.ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: var(--inner-tick-color);
}

.ant-checkbox-checked:after {
  border-color: var(--inner-tick-color);
  border: none;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  
  background-color: var(--inner-background-color);
  border-color: var(--inner-background-color);

}

.ant-picker:hover {
  border-color: #616161;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #d9d9d9;
}


thead tr th:first-child .ant-checkbox .ant-checkbox-inner {
  width: 20px;
  height: 20px;
  background-color: transparent;
  border-color: black;

}

thead tr th:first-child .ant-checkbox-inner:after {
  border-color: white;

}

thead tr th:first-child .ant-checkbox-checked .ant-checkbox-inner {
  width: 20px;
  height: 20px;
  background-color: #04B7B1;
  border-color: #04B7B1;
}

thead tr th:first-child .ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: white;
}

table tbody .ant-checkbox .ant-checkbox-inner {
  width: 20px;
  height: 20px;
  background-color: transparent;
  border-color: black;
}


tbody tr .ant-checkbox-checked .ant-checkbox-inner {
  width: 20px;
  height: 20px;
  background-color: #04B7B1;
  border-color: #04B7B1;

}

tbody tr .ant-checkbox-checked .ant-checkbox-inner:after{
  border-color: white;
}
tbody tr .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  
  background-color: #04B7B1;
  border-color: #04B7B1;

}

thead tr .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  
  background-color: #04B7B1;
  border-color: #04B7B1;

}

.ant-card{
  border-radius: 5px;
}

.ant-card .ant-card-body {
  padding: 9px 18px !important;
}

.ant-tabs-nav {
  background-color: transparent;
  font-family: "Urbanist", sans-serif;
  font-weight: 550;
}

.ant-tabs-tab {
  color: #183433 !important;
  font-size: 0.95vw !important;
}

.ant-tabs-tab:hover,
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #04B7B1 !important;
}

.ant-tabs-ink-bar {
  background-color: #04B7B1 !important;
}

/* .ant-picker-footer .ant-picker-ok .ant-btn-primary span {
  color: white;
} */

.ant-picker-footer .ant-picker-ok button {
  border: 0.1em solid #7bbeac;
  background-color:#7bbeac;
}

.round-image-container {
  position: relative;
}

.round-image {
  width: 7dvw; /* Set your desired image size */
  height: 7dvw;
  border-radius: 50%;
  object-fit: cover;
}

.hand-image-overlay {
  position: absolute;
  bottom: 6.35dvw;
  left: 40%;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.hand-image {
  width: 2.5dvw; /* Set your desired hand image size */
  height: auto;
  opacity: 0.8;
}

.text-overlay {
  position: absolute;
  bottom: 3.125vw;
  left: 50%;
  transform: translateX(-50%);
  padding: 2.25vw 0.625vw;
  border-radius: 5px;
  font-size: 0.80vw;
  color: white;
}

.text-overlay-shift {
  width: 100%;  
  position: absolute;
  bottom: 3.5vw;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
  font-size: 0.590vw;
  color: white;
  text-align: center;
}

.custom-box {
  border: 1px solid #E9E9E9;
  padding: 5px;
  position: relative;
  margin-bottom: 5px;
  background-color: #fff;
  transition: opacity 0.3s ease-in-out;

  &.hidden {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    padding: 0;
  }
}

.close-button {
  position: absolute;
  top: 2px;
  right: 8px;
}

.ant-table-selection-column .ant-table-selection .ant-checkbox .ant-checkbox-inner {
  width: 15px;
  height: 15px;
  /* background-color: #04B7B1; */
  border-color: gray;
}

.ant-table-selection-column .ant-checkbox .ant-checkbox-inner {
  width: 16px;
  height: 16px;
  /* background-color: #04B7B1; */
  border-color: gray;
}

.clockContainer {
  position: absolute;
  top: 14%;
  left: 25.5%;
  z-index: 1;
  background-color: transparent;
}

.clockTimerTxt {
  width: 100%;
  background-color: transparent;
  color: black;
  font-size: 1.35dvw;
  text-align: center;
}

.shiftTimerTxt {
  width: 100%;
  background-color: transparent;
  color: black;
  font-size: 0.625dvw;
  margin-bottom: 0.2dvw;
}

.playPauseIcon {
  width: 2.2dvw;
  height: 2.2dvw;
  margin: 0.2dvw;
  cursor: pointer;
}

.noticeImg {
  width: 20dvw;
  height: 13dvw;
}

.event_icons {
  width: 1.5dvw;
  height: 1.3dvw;
}

.clickableContainer {
  width: 13vw;
  height: 6.6vw;
  margin-right: 11px;
  text-align: center;
  cursor: pointer;
}

.clickable_countTxt {
  color: #183433;
  font-family: "Urbanist", sans-serif;
  font-size: 2vw;
  font-weight: 700;
}

.clickable_lblTxt {
  font-size: 0.95vw;
  color: #6d6b6b;
  font-weight: 500;
  font-family: "Urbanist", sans-serif;
}

.amountContainer {
  width: 16.5vw;
  height: 6.6vw;
  margin-right: 11px;
}

.amount_countTxt {
  color: #183433;
  font-family: "Urbanist", sans-serif;
  font-size: 1.4vw;
  font-weight: 550;
}

.commonTblContentTxt {
  font-size: 0.90vw;
  color: #6d6b6b;
  font-weight: 500;
  font-family: "Urbanist", sans-serif;
}

/* Filter styles */
.filterTltDiv {
  border-bottom: 1px solid #cbcbcb;
  padding: 0.3vw;
}

.fltTextField {
  width: 17vw;
  outline: none;
}

.fltCloseIcon {
  color: #616161;
  font-size: 0.95vw !important;
  font-weight: bolder !important;
}

.fltDatepickertextField {
  width: 100%;
  outline: none;
}

.fltContentDiv {
  padding: 0.625vw;
  height: 80%;
}

.fltTextFieldDiv {
  margin: 10px;
}