.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

:root {
  --table-container-footer: 140px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline {
  background: #04B7B1;
}

.ant-menu-dark .ant-menu-item-selected {
  background-color: #03a09b;
}

.anticon svg {
  display: inline-block;
  vertical-align: baseline;
}

.ant-btn{
  box-shadow: none;
}

.ant-menu-inline .ant-menu-item {
  height: 3.8dvh;
  line-height: 5.15dvh;
  list-style-position: inside;
  list-style-type: disc;
}

.ant-menu-inline >.ant-menu-item, .ant-menu-vertical >.ant-menu-item, .ant-menu-inline >.ant-menu-submenu>.ant-menu-submenu-title,.ant-menu-vertical >.ant-menu-submenu>.ant-menu-submenu-title {
  height: 3.8dvh;
  line-height: 5.15dvh;
}

.ant-menu .ant-menu-submenu-arrow{
  width: 0.6dvw !important;
  inset-inline-end: 1dvw !important;
}

.pattern-text {
  position: relative;
}
.text-on-image {
 position: absolute;
 right: 20%;
 left: 30%;
 bottom: 15%;
}

.ReactTags__tagInput input.ReactTags__tagInputField, .ReactTags__tagInput input.ReactTags__tagInputField:focus{
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid;
  border-color: #616161;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(87, 167, 233, 0);
  box-shadow: 0 0 0 2px rgba(87, 167, 233, 0);
  padding-left: 0%;
  padding: 5px 5px 5px 0px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 100;
  font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
  height: 34;
}

.ant-input , .ant-input-password{
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid;
  border-color: #616161 !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(87, 167, 233, 0);
  box-shadow: 0 0 0 2px rgba(87, 167, 233, 0);
  padding: 0.31vw 0.2vw !important;
  height: 2vw;
  font-size: 1vw !important;
}

.ant-input-password{
  padding-left: 0.18vw !important;
}

.ant-input-password .ant-input {
  height: 100%;
  line-height: normal;
  vertical-align: middle;
}

.ant-input-password .ant-input-suffix {
  line-height: 0;
  vertical-align: middle;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder{
  line-height: normal !important;
}
.ant-input-prefix {
  font-size: 0.95vw !important;
}

.ant-input-affix-wrapper {
  -webkit-box-shadow: 0 0 0 2px rgba(87, 167, 233, 0);
  box-shadow: 0 0 0 2px rgba(87, 167, 233, 0);
  border-color: #616161 !important;
  padding: 0px !important;
}

.custom-auto-complete-container {
  display: flex;
  align-items: center;
  width: 22vw;
  margin-right: 0.8vw;
  border-radius: 3.5px;
  border: 1px solid #616161;
  background-color: #ffffff;
  height: fit-content;
}

.custom-auto-complete {
  border: none !important;
}

.custom-auto-complete .ant-select-selector {
  width: 100%;
  height: 100%;
  padding: 0 !important;
  display: flex;
  align-items: center;
}

.custom-auto-complete .ant-select-selector .ant-select-selection-placeholder {
  position: absolute;
  inset-inline-start: 11px;
  inset-inline-end: 11px;
}

/* .ant-input-search {
  border: 0;
  border-radius: 0;
  border-color: #616161;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(87, 167, 233, 0);
  box-shadow: 0 0 0 2px rgba(87, 167, 233, 0);
  padding-left: 0%;
  padding: 5px 5px 5px 0px;
} */

.ant-picker {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid;
  border-color: #616161;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(87, 167, 233, 0);
  box-shadow: 0 0 0 2px rgba(87, 167, 233, 0);
  padding: 0.31vw 0vw 0.31vw 0.69vw;
  height: 2vw !important;
  font-size: 1vw !important;
}

.ant-steps-item-title, 
.ant-notification-notice-message,
.ant-input-suffix,
.ant-picker-input > input,
.ant-picker-dropdown,
.ant-picker-suffix .anticon,
.ant-picker-panel-arrow{
  font-size: 1vw !important;
}

.ant-btn.ant-btn-icon-only,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
  height: 2vw !important;
  text-align: start !important;
}

.ant-select-selection-search{
  inset-inline-start: 0.2vw !important;
  inset-inline-end: 0.2vw !important;
}

.ant-checkbox-wrapper{
  font-size: 0.95vw !important;
  font-style: "Urbanist", sans-serif;
  font-weight: 600;
}

.ant-notification-notice-icon{
  font-size: 1.5vw !important;
}

.ant-input:focus {
  border-color: #616161;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(87, 167, 233, 0);
  box-shadow: 0 0 0 2px rgba(87, 167, 233, 0);
}

.ant-input:hover {
  border-color: #616161;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(87, 167, 233, 0);
  box-shadow: 0 0 0 2px rgba(87, 167, 233, 0);
}

.ant-select-selector {
  border-radius: 0;
  border: none !important;
  box-shadow: none !important;
  padding: 0.31vw 0.2vw !important;
  height: 2dvw !important;
  font-size: 1dvw !important;
}

.ant-select-multiple .ant-select-selector:after {
  margin: 0px !important;
  line-height: normal !important;
}

.ant-select {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid;
  border-color: #616161;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(87, 167, 233, 0);
  box-shadow: 0 0 0 2px rgba(87, 167, 233, 0);
}


.ant-select-status-error {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid !important;
  border-color: #ff4d4f !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(87, 167, 233, 0);
  box-shadow: 0 0 0 2px rgba(87, 167, 233, 0);
}

.ant-input-status-error.ant-input-disabled,
.ant-input-status-error.ant-input-disabled:hover {
  border-color: #ff4d4f;
}

.ant-upload-wrapper-status-error {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid;
  border-color: #ff4d4f;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(87, 167, 233, 0);
  box-shadow: 0 0 0 2px rgba(87, 167, 233, 0);
}

.ant-input-affix-wrapper-status-error {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid;
  border-color: #ff4d4f;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(87, 167, 233, 0);
  box-shadow: 0 0 0 2px rgba(87, 167, 233, 0);
}

.ant-upload-wrapper{
  font-size: 0px !important;
  line-height: 0 !important;
}

.ant-form-item{
  margin-bottom: 0.5vw;
}

.ant-form-item .ant-form-item-label >label {
  color: #616161 !important;
  font-size: 0.95vw;
  font-family: "Urbanist", sans-serif;
  font-weight: 600;
}

.ant-input-number {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid;
  border-color: #616161;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(87, 167, 233, 0);
  box-shadow: 0 0 0 2px rgba(87, 167, 233, 0);
  padding: 0.31vw 0.31vw 0.31vw 0vw;
}

.ant-btn{
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid;
  border-color: #616161;
  font-size: 1vw !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(87, 167, 233, 0);
  box-shadow: 0 0 0 2px rgba(87, 167, 233, 0);
  padding: 0.31vw;
  margin-bottom: -1px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-input-group .ant-input{
border: 1px solid;
border-color: #d9d9d9;
font-size: 12px;
padding: 6px;
}

.ant-input-group .ant-input-group-addon{
  border-radius: 0;
  padding: 8px;
}

.input-otp__field {
  height: 64px;
  max-width: 64px;
  background-color: #F5F8F7;
  border: 1px solid;
  border-color: #E9E9E9 ;
}

.textarea{
  border: 1px solid;
  padding: 10px;
}

textarea#basicform_reason{
  border: 1px solid #cacaca;
}
textarea#basicform_reason.ant-input-status-error{
  border: 1px solid #ff4d4f;
}

.ant-table-thead th{
  font-family: "Urbanist", sans-serif;
  font-weight: bold;
  color: #183433 !important;
  font-size: 0.94vw;
}

.ant-table-tbody > tr {
  font-family: "Urbanist", sans-serif;
  font-size: 0.9vw;
  font-weight: 400;
}

/* table height changes start */
.ant-table-thead > tr > th {
  height: 40px; 
}

.ant-pagination {
  height: 40px;
  margin: 11.2px;
}

.ant-table-footer {
  height: 0;
}

.ant-table-body {
  /* Set a default height for the table body */
  height: calc(var(--table-container-height) - var(--table-container-footer)) !important;
  overflow: auto !important;
}

.ant-table-placeholder {
  height: calc(var(--table-container-height) - var(--table-container-footer)) !important;
  margin: 0 auto;
}

.ant-pagination-item,
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-options-quick-jumper {
  font-size: 0.95vw !important;
  font-family: "Urbanist", sans-serif !important;
  color: #183433 !important;
  font-weight: 600;
}

.ant-pagination-item-active {
  border-color: #04B7B1 !important;
}

.ant-pagination-item-active a {
  color: #04B7B1 !important;
}
/* table height changes end */

.ant-typography{
  font-family: "Urbanist", sans-serif;
}

.ant-checkbox-inner {
  --background-color: white;
  --border-color: grey;
  --inner-background-color: #04B7B1;
  --inner-border-color: black;
  --width: 1.25vw;
  --height: 1.25vw;
  --inner-tick-color: white;
  font-family: "Urbanist", sans-serif;
}

.ant-tooltip .ant-tooltip-inner,
.ant-tooltip-placement-top .ant-tooltip-arrow::before,
.ant-tooltip-placement-bottom .ant-tooltip-arrow::before,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow::before,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow::before {
  background-color: #616161;
}

.ant-input::placeholder,
.ant-select-selector .ant-select-selection-placeholder,
.ant-picker-input input::placeholder {
  color: #858080 !important;
  font-size: 0.9vw;
  font-family: "Urbanist", sans-serif;
  font-weight: 500;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ant-dropdown {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.ant-avatar {
  width: 2vw !important;
  height: 2vw !important;
  border-radius: 50%;
}

.ant-picker-dropdown .ant-picker-time-panel .ant-btn-primary {
  background-color: #04B7B1 !important;
  border-color: #04B7B1 !important;
  color: #ffffff;
}

.ant-radio-wrapper .ant-radio {
  color: #183433;
  font-family: "Urbanist", sans-serif;
  font-size: 1.2vw;
  font-weight: 600;
  width: 1.25vw;
  height: 1.25vw;
}

.ant-radio-inner {
  border-color: #04B7B1 !important;
}

.ant-radio-inner::after {
  background-color: #04B7B1 !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #04B7B1 !important;
  background-color: #04B7B1 !important;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon{
  background-color: #04B7B1 !important;
  border-color: #04B7B1 !important;
}

.ant-steps-item-icon{
  font-size: 0.75vw !important;
}

.ant-form-item-explain{
  font-size: 0.8vw !important;
  font-family: "Urbanist", sans-serif;
  font-weight: 600;
}

.ant-collapse {
  border: none;
  outline: none;
  border-radius: 0;
}

.ant-collapse-item {
  background-color: white;
  border-radius: 0px;
  outline: none;
}

.ant-collapse-item:last-child {
  background-color: white;
  border-radius: 0px !important;
  outline: none;
}

.ant-collapse-item.ant-collapse-item-active {
  background-color:#E4F2F0 ;
}

.ant-select-arrow {
  font-size: 0.85vw !important;
  color: #183433 !important;
  font-weight: bold !important;
}

.ant-select-dropdown {
  max-height: 300px !important;
  overflow-y: auto;
}
.ant-select-item {
  font-size: 0.85vw !important; 
}

.ant-input-number-handler-wrap {
  display: none !important;
}

.ant-input-number-input {
  text-align: center !important;
}

.ant-spin-dot-item {
  background-color: #04B7B1 !important;
}
/* 
.ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  transform: rotate(180deg) !important;
}

.ant-menu-submenu-open > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  transform: rotate(180deg) !important;
} */

.ant-checkbox-wrapper:hover .ant-checkbox-inner{
  border-color: #04B7B1 !important;
}

.ant-picker-cell-today .ant-picker-cell-inner::before{
  border-color: #04B7B1 !important; 
}

.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-selected .ant-picker-cell-inner:hover {
  background-color: #04B7B1 !important;
  border-color: #04B7B1 !important;
}

.ant-select-selector .ant-select-selection-overflow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  overflow: hidden;
  position: absolute;
}

.ant-select-multiple .ant-select-selection-item{
  display: flex;
  line-height: normal !important;
  font-size: 0.95dvw !important;
  height: 2dvw !important;
  justify-content: space-between;
  align-items: center;
}

.ant-select-multiple .ant-select-selection-item-remove{
  font-size: 0.95dvw !important;
}

.custom-radio .ant-radio-wrapper {
  color: #183433 !important;
  font-family: "Urbanist", sans-serif;
  font-size: 0.90dvw !important;
  font-weight: 600;
}

/* common styles */
.common_add_tltTxt {
  color: #183433;
  font-family: "Urbanist", sans-serif;
  font-size: 1.2vw;
  font-weight: 600;
}

.common_employee_nameTxt {
  color: #183433;
  font-family: "Urbanist", sans-serif;
  font-size: 0.95vw;
  font-weight: 600;
}

.common_employee_desTxt {
  font-family: "Urbanist", sans-serif;
  font-size: 0.80vw;
}
.pla_desTxt {
  font-family: "Urbanist", sans-serif;
  font-size: 0.80vw;
  color:grey;
}
.common_tltTxt {
  color: #183433;
  font-family: "Urbanist", sans-serif;
  font-size: 2vw;
  font-weight: 600;
  padding: 0 !important;
  margin: 0 !important;
}
.common_header_txt{
  color: #183433;
  font-family: "Urbanist", sans-serif;
  font-size: 2vw;
  font-weight: 600;
  margin-top: 2vw;
}


.common_addTltTxt {
  color: #183433;
  font-family: "Urbanist", sans-serif;
  font-size: 1.6vw;
  font-weight: 600;
}

.tbl_headertxt {
  color: #183433;
  font-family: "Urbanist", sans-serif;
  font-size: 1.1vw;
  font-weight: 600;
  margin-bottom: 0.625vw;
  padding-bottom: 0.5vw;
}

#common_cancelTxt {
  color: #183433;
  font-family: "Urbanist", sans-serif;
  font-size: 1.1vw;
  font-weight: 700;
  text-align: center;
}

#common_weekoffTxt {
  color: #183433;
  font-family: "Urbanist", sans-serif;
  font-size: 1vw;
  font-weight: 700;
  margin-bottom: 0.5vw;
}

#common_weekoff_valTxt {
  color: #183433;
  font-family: "Urbanist", sans-serif;
  font-size: 0.95vw;
  font-weight: 700;
}

.custom-checkbox .ant-checkbox-inner {
  width: 1.25vw;
  height: 1.25vw;
  background-color: red !important;
  border-color: grey;
}

.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: green !important;
}

.custom-checkbox .ant-checkbox-inner::after {
  border-color: transparent;
}

#common_ApprovalTxt {
  color: #183433;
  font-family: "Urbanist", sans-serif;
  font-size: 1.1vw;
  font-weight: 700;
  margin-bottom: 0.625vw;
  padding-bottom: 0.5vw;
  border-bottom: 1px solid #cbcbcb;
}

#common_reimburse_supportTxt {
  color: #616161;
  font-family: "Urbanist", sans-serif;
  font-size: 0.80vw;
  font-weight: 500;
  text-align: center;
  }
  
  .common_reimburse_removeIcon {
    position: 'absolute';
    top: 19px;
    left: 95.5%;
    z-index: 1;
  }
  
  .common_reimburse_pdfIcon {
    position: 'absolute';
    top: 19px;
    left: 70%;
    z-index: 1;
}

#common_salary_Txt {
  color: #183433;
  font-family: "Urbanist", sans-serif;
  font-size: 0.9vw;
  font-weight: 700;
}
#common_salary_subTxt {
  color: #183433;
  font-family: "Urbanist", sans-serif;
  font-size: 0.85vw;
  font-weight: 600;
}

#common_admin_orgTlt {
  font-family: "Urbanist", sans-serif;
  font-size: 0.80vw;
  color: #616161;
  font-weight: 500;
}

#common_admin_orgTxt {
  color: #183433;
  font-family: "Urbanist", sans-serif;
  font-size: 0.95vw;
  font-weight: 650;
}

.admin_orgLogo_div1 {
  width: 70%;
  height: 70%;
  border: 1px solid #D6D6D6;
  margin: 0 auto;
}

.admin_orgLogo_div2 {
  width: 95%;
  height: 96%;
  border: 1px solid #D6D6D6;
  background: #EFEFEF;
  margin: 0.3vw auto;
}

.admin_orgLogo {
  width: 95%;
  height: 96%;
  border: 3px dashed #D6D6D6;
  margin: 0.3vw auto;
}

.admin_org_pdf {
  width: 2vw;
  height: 2vw;
  margin-right: 0.3vw;
}
.common_filters_subTxt {
  color: #616161;
  font-family: "Urbanist", sans-serif;
  font-size: 0.85vw;
  font-weight: 400;
}

.custom-radio-group .ant-radio-button-wrapper,
.custom-roster-radio-group .ant-radio-button-wrapper {
  border-color: #CACACA !important;
  color: #183433 !important;
  border-radius: 0 !important;
  font-size: 0.95vw !important;
  font-weight: 650;
  font-family: "Urbanist", sans-serif;
  outline: none;
}

.custom-radio-group .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked) {
  background-color: #FFFFFF !important;
}

.ant-upload:hover {
  border-color: #04B7B1 !important;
}

.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after{
  background-color: #04B7B1 !important;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #DBF3E9;
  border-color: #DBF3E9;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon >.ant-steps-icon{
  color: #04B7B1 !important;
}

.ant-badge .ant-badge-count {
  min-width: 1.25vw;
  height: 1.25vw;
  font-size: 0.95vw !important;
  line-height: 1.25vw;
}

/* Custom styles for checked radio buttons */
.custom-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  background-color: #CACACA !important;
}

.custom-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #CACACA !important;
}

.custom-roster-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #04B7B1 !important;
}

.custom-roster-radio-group .ant-radio-button-wrapper-checked {
  color: #fff !important; 
}

.custom-roster-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  background-color: #04B7B1 !important;
  border-color: #04B7B1 !important; 
}

#userPermission_collapse_div {
  background-color: white;
  height: 2.8vw;
  padding-left: 33px;
  padding-right: 12px;
}

#userPermission_collapse_Subdiv {
  background-color: white;
  height: 2.8vw;
}

.userPermission-checkbox {
  font-size: 0.90vw !important;
  font-family: "Urbanist", sans-serif;
  font-weight: 700 !important;
}

.userPermission-checkbox-tlt {
  color: #183433;
}

.userPermission-checkbox-subTlt {
  color: #504e4e;
}

.common_home_noticedescTxt {
  font-size: 0.95dvw;
  font-family: "Urbanist", sans-serif;
  font-weight: 600;
}

.alert_txt {
  color: red;
  font-size: 0.70vw;
  font-family: "Urbanist", sans-serif;
  font-weight: 500;
  background: #FAF9F9;
}

.success_txt {
  color: rgb(0, 198, 0);
  font-size: 0.70vw;
  font-family: "Urbanist", sans-serif;
  font-weight: 500;
  padding-top: 3px;
  background-color: white;
}
.password-checkbox {
  font-size: 0.85vw !important;
  font-family: "Urbanist", sans-serif;
  font-weight: 500 !important;
}

.password-tlt {
  font-size: 0.80vw;
  font-family: "Urbanist", sans-serif;
  font-weight: 700;
  color: #6d6b6b;
}

.login_tlt {
  font-family: "Urbanist", sans-serif;
  font-weight: 650;
  color: #202020 !important;
  font-size: 1.3vw;
}

.login-checkbox {
  font-size: 0.90vw !important;
  font-family: "Urbanist", sans-serif;
  font-weight: 600 !important;
}

.login-checkbox-tlt {
  color: #202020;
}

.forgot_BackIcon {
  width: 1.3vw;
  height: 1.3vw;
  margin-right: 0.5vw;
}

.login_Logo {
  width: 20vw;
  height: 3vw;
  margin-top: 0.625vw;
  margin-left: -1.8vw;
}

.login_success_icon {
  width: 6vw;
  height: 6vw;
  margin: 0 auto;
}

#common_home_leaveTxt {
  color: #183433;
  font-family: "Urbanist", sans-serif;
  font-size: 2dvw;
  font-weight: 700;
  margin-bottom: 0.2dvw;
}

#common_home_upcomingTxt {
  font-family: "Urbanist", sans-serif;
  font-size: 0.80dvw;
  color: #183433;
  font-weight: 700;
}

.common_home_noticeTxt {
  color: #202020 !important;
  font-size: 0.95vw;
  font-family: "Urbanist", sans-serif;
  font-weight: 600;
}

.list-container {
  width: 28vw;
  max-height: 100vh;
  overflow-y: auto;
  position: absolute;
  top: 10%;
  right: 7%;
  z-index: 100;
  box-shadow: 0 2px 5px gray;
  border-radius: 4px;
  padding: 1vw;
  background-color: white;
  transition: opacity 0.3s ease;
  opacity: 0;
  pointer-events: none;
  }
  
  .list-container.visible {
    opacity: 1;
    pointer-events: auto;
  }

.scrollable-container{
  max-height: 45vh;
  overflow-y: auto;
}

.list-header {
  color: #183433 !important;
  font-family: "Urbanist", sans-serif !important;
  font-size: 0.9vw !important;
  font-weight: 700 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2.5vh;
  margin-bottom: 0.5vw;
}

.happyMeter_icons {
  width: 3.5dvw !important;
  height: 3.5dvw !important;
}

.happyMeter_txt {
  text-align: center;
  color: #6d6b6b !important;
  font-family: "Urbanist", sans-serif !important;
  font-size: 0.9vw !important;
  font-weight: 700 !important;
}

.ant-list .ant-list-header {
  padding: 2px;
}

.custom-list-item-meta .ant-list-item-meta-title {
  color: #183433 !important;
  font-family: "Urbanist", sans-serif !important;
  font-size: 0.9vw !important;
  font-weight: 700 !important;
}

.custom-list-item-meta .ant-list-item-meta-description {
  color: #183433 !important;
  font-family: "Urbanist", sans-serif !important;
  font-weight: 600 !important;
}

.custom-modal .ant-modal-content {
  border-radius: 0;
}

.common_DayWiseTxt {
  color: #183433;
  font-family: "Urbanist", sans-serif;
  font-size: 1.1vw;
  font-weight: 700;
}

.common_dayWiseTltTxt {
  font-size: 0.9vw;
  color: #6d6b6b;
  font-weight: 500;
  font-family: "Urbanist", sans-serif;
}

.custom-table .ant-table-thead th{
  font-family: "Urbanist", sans-serif;
  font-weight: bold;
  color: #183433 !important;
  font-size: 0.94vw; 
  border-bottom: 1px solid #183433 !important;
}

.common_DayWiseSubTxt {
  color: #183433;
  font-family: "Urbanist", sans-serif;
  font-size: 0.95vw;
  font-weight: 700;
}

.dotBtn {
  width: 1vw;
  height: 1vw;
  border-radius: 50%;
  margin-right: 0.5vw;
}

.runpayRoll_Txt {
  color: #183433;
  font-family: "Urbanist", sans-serif;
  font-size: 1.5vw;
  font-weight: 600;
}

.planrs_Txt {
  font-family: "Urbanist", sans-serif;
  font-size: 2.5vw;
  font-weight: 500;
}

.runpayRoll-editPop-tlt {
  font-size: 0.95vw;
  font-family: "Urbanist", sans-serif;
  font-weight: 400;
  color: #aaa3a3;
}

.commonInputTlt {
  color: #858080 !important;
  font-size: 0.85vw;
  font-family: "Urbanist", sans-serif;
  font-weight: 500;
  margin: 0px !important;
  padding: 0px !important;
}

.requiredTxt {
  color: red
}

.custom_card{
  padding: 0.5vw;
}

.statusDiv {
  width: 8vw;
  padding: 0.35vw 0vw;
  font-size: 0.95vw;
  font-family: "Urbanist", sans-serif;
  text-align: center;
  text-transform: capitalize;
}

.empApproveTxt {
  font-size: 0.95vw;
  font-family: "Urbanist", sans-serif;
  cursor: pointer;
  font-weight: 500;
}

.commonTblBackIcon {
  width: 2dvw;
  height: 2dvw;
}

.homeNotFoundIcon{
  margin:50% auto;
  overflow: hidden !important;
  height: 12dvw;
}

.notifyIcon{
  width: 1.3vw;
  height: 1.3vw;
}
.notifyTxt{
  font-size: 0.9vw;
}

.plusIcon{
font-size: 1vw;
}

.layoutIcon {
  width: 1.6vw;
  height: 1.6vw;
}

.loaderOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
}

.nestedTbl {
  width: 100%;
  border-collapse: collapse !important;
}

.nestedTblColumn {
  border: 1px solid #ccc;
  padding: 2px 5px;
  font-size: 0.8vw !important;
  font-weight: 500;
}

.nestedCloumnTxt {
  font-size: 0.8vw !important;
  font-weight: 600 !important;
  font-family: "Urbanist", sans-serif;
}

.musterStatusTxt {
  color: #183433;
  font-family: "Urbanist", sans-serif;
  font-size: 0.95vw;
  font-weight: 700;
}
.shiftNoteTxt {
  color: red;
  font-size: 1vw;
  font-family: "Urbanist", sans-serif;
  font-weight: 400;
}

.rosteringIcon {
  width: 1dvw;
  height: 1dvw;
}

.rosteringBackIcon {
  width: 1.3dvw;
  height: 1.3dvw;
}

.rostering_Txt {
  color: #183433;
  font-family: "Urbanist", sans-serif;
  font-size: 1.1dvw;
  font-weight: 600;
}

.rostering_date_Txt {
  color: #616161;
  font-family: "Urbanist", sans-serif;
  font-size: 0.65dvw;
  font-weight: 600;
  border-radius: 10px;
}

.rostering_split_txt{
  font-size: 0.85dvw;
}

.rostering_date_btn {
  width: 10dvw;
  height: 100%;
  padding: 0.25dvw;
  text-align: center;
}

.rostering_groupby_txt {
  background: #EEF7F6;
  height: 4dvh;
  color: #183433;
  display: flex;
  align-items: center;
  padding-left: 5px;
}

.custom-roster-checkbox .ant-checkbox-wrapper {
  margin-right: 0;
  padding-right: 0;
}

.custom-roster-checkbox .ant-checkbox+span {
  margin: 0;
  padding-left: 4px;
}

.rostering_shift_btn {
  height: 3dvw;
  padding: 0.25dvw;
  margin: 0 2px;
  text-align: center;
  border: 2px solid #04B7B1;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.rostering_recurring_txt {
  font-size: 0.95dvw;
  font-weight: 600;
  color: #183433;
}

.rostering_cpyshf_container {
  height: 35dvh;
}

.checkbox-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 4px;
}

.rostering_flt_subDiv{
  width: 100%;
  margin-bottom: 10px;
}
.common_subordi_employee_nameTxt {
  font-size: 0.85vw;
}

.common_subordi_employee_desTxt {
  font-size: 0.70vw;
}

.commonTooltipOfMutliSlt {
  background-color: white;
  padding: 5px;
  border: 1px solid #ccc;
}

.commonTooltipOfMutliSltTxt{
  font-size: 0.85dvw;
  font-family: "Urbanist", sans-serif;
  width: fit-content;
  padding: 5px;
}

.commonTooltipOfMutliSltDiv {
  list-style-type: none;
  padding: 0;
}

.input-group-container .ant-input-group-wrapper {
  display: flex;
  align-items: center;
}

.input-group-container .ant-input-group-addon {
  display: flex inline-flex;
  align-items: center;
  padding: 0 8px;
}

.input-group-container .ant-input {
  line-height: normal; 
}

.carousel-wrapper {
  position: relative;
}

.carousel-item {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 65dvh;
}

.carousel-image {
  width: 95%;
  height: 85%;
  object-fit: contain;
  margin: 20px auto; 
  margin-bottom: 10px;
}

.carousel-pdf-image {
  width: 80%;
  height: 70%;
  object-fit: contain;
  margin: 20px auto; 
}

.custom-carousel .slick-dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.carousel-wrapper .custom-carousel .slick-dots li.slick-active button {
  background-color: #04B7B1 !important;
  height: 4px;
}

.carousel-wrapper .custom-carousel .slick-dots li button {
  background-color: gray !important;
  height: 4px;
}

.custom-carousel .slick-prev {
  left: -10px;
}

.custom-carousel .slick-next {
  right: -10px;
}

.custom-carousel .slick-prev::before{
  color: #04B7B1;
  content: '‹' !important; 
  font-size: 3.5vw;
}

.custom-carousel .slick-next::before {
  color: #04B7B1;
  content: '›' !important;
  font-size: 3.5vw;
}

.custom-carousel .slick-prev.slick-disabled,
.custom-carousel .slick-next.slick-disabled {
  cursor: not-allowed;
}

.emp_img {
  width: 100%;
  height: 100%;
  margin: 25px auto;
  margin-bottom: 10px;
}

.alertbox {
  border: 1px solid red  !important;
  padding: .25vw .8vw !important;
  border-radius: 3px;
  box-shadow:  1px 4px -1px #ff8989;
}

.validatebox {
  border: 1px solid #a0a2a4  !important;
  padding: .25vw .8vw !important;
  border-radius: 3px;
  box-shadow:  1px 14px -1px #c7c7c7;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: white !important;
}

.highlight-disabled-row {
  background-color: #f5f5f5;
  color: #888;
}

.processrec {
  font-size: 0.9rem;
  color: #0fa80f;
  font-weight: 500;
}

.conflictrec {
  font-size: 0.9rem;
  color: #e71a1a;
  font-weight: 500;
}
.custom-switch.ant-switch {
  width: 30px; /* Adjust the width according to your design */
  height: 20px; /* Adjust the height according to your design */
  background: #ffffff; /* Background color similar to your image */
  border-radius: 30px; /* Make the switch oval */
  border: none;
  box-shadow: none;
  margin: 0vw 1.5vw;
}

.custom-switch.ant-switch-checked {
  background: #ffffff; /* Color of the switch when it's turned on */
}

.custom-switch .ant-switch-handle {
  width: 18px; /* Adjust the handle size */
  height: 17.5px; /* Adjust the handle size */
  background-color: #ffffff; /* Color of the handle */
  top: 0.2px;
  box-shadow: none;
  border-radius: 10px;
}

/* Handle hover state */
.custom-switch.ant-switch:hover {
  background: #ffffff !important; /* Keep the background white on hover */
}

.custom-switch.ant-switch-checked:hover {
  background: #ffffff !important; /* Keep background white when checked and hovered */
}

.ant-switch .ant-switch-handle::before {
  background-color: #00a7a7;
}

.selected-plan {
  background-color: #04B7B1; /* Background color for selected plan */
  color: white; /* Text color for selected plan */
  /* border: 2px solid #04B7B1; */
  /* Border color for selected plan */
  padding: 0px !important;

}
.selected-plan .sub_txt {
  color: white; 
}
.selected-plan  .header_txt {
  font-family: "Urbanist", sans-serif;
  font-size: 1.3vw;
  font-weight: 600;
}


.normal-plan {
  background-color: white; /* Background color for normal plan */
  color: black; /* Text color for normal plan */
  /* border: 1px solid #e0e0e0; */
  /* Border color for normal plan */
}
.normal-plan .sub_txt {
  color: grey; 
}
.normal-plan .header_txt {
  color: #183433;
  font-family: "Urbanist", sans-serif;
  font-size: 1.3vw;
  font-weight: 600;
}

.selected-plan .ant-card-title,
.selected-plan .ant-card-extra {
  color: white;
}

.normal-plan .ant-card-title,
.normal-plan .ant-card-extra {
  color: black;
}

.ant-card-actions > li > span {
  width: 100%;
  display: flex;
  justify-content: center;
}

.ant-card-actions > li > span > .ant-btn {
  width: 100%;
}
  
.plan_btn{
  border: 1px solid #5adcdc;
  padding: 0vw;
  width: -webkit-fill-available;
  color: #5adcdc;
  font-weight: 600;
  margin-bottom: 1vw;
  background-color: #ffffff;
}
.toggleContainer {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: fit-content;
  border: 1px solid #c8c8c8;
  border-radius: 20px;
  background: #ffffff;
  font-weight: bold;
  color: #343434;
  cursor: pointer;
  font-size: 12px !important;
}
.toggleContainer::before {
  content: '';
  position: absolute;
  width: 50%;
  height: 100%;
  left: 0%;
  border-radius:20px;
  background: #04B7B1;
  transition: all 0.3s;
}
.toggleCheckbox:checked + .toggleContainer::before {
   left: 50%;
}
.toggleContainer div {
  padding: 6px;
  text-align: center;
  z-index: 1;
}
.toggleCheckbox {
  display: none;
}
.toggleCheckbox:checked + .toggleContainer div:first-child{
  color: rgb(111, 111, 111);
  transition: color 0.3s;
}
.toggleCheckbox:checked + .toggleContainer div:last-child{
  color: #eeeeee;
  transition: color 0.3s;
}
.toggleCheckbox + .toggleContainer div:first-child{
  color: #eeeeee;
  transition: color 0.3s;
}
.toggleCheckbox + .toggleContainer div:last-child{
  color: rgb(111, 111, 111);
  transition: color 0.3s;
}
.addText{
  color:#04B7B1;
  font-weight: 500;
}

.highlight_txt {
  color: red;
  font-weight: 500;
  font-size: small;
  align-items: center;
  display: flex;
}

.selected-plan .ant-collapse-header{
  background-color: #04b7b1;
}

.ant-collapse-header{
  border: 0px;
  padding: 10px !important;
}
.submenuAcc .ant-collapse-header{
  background-color: white !important;
}

.parent {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, 1fr);
  gap: 8px;
  height: 79vh; /* Ensures full-page height */
}

.div1,
.div2,
.div3 {
  grid-column: span 2 / span 2;
  grid-row: span 2 / span 2;
  min-height: 100%; /* Stretch to match grid height */
}

.div2 {
  grid-column-start: 1;
  grid-row-start: 3;
}

.div3 {
  grid-column-start: 1;
  grid-row-start: 5;
}

/* Make div4 and div5 full height with scrollable content */
.div4 {
  grid-column: span 2 / span 2;
  grid-row: span 6 / span 6;
  grid-column-start: 3;
  grid-row-start: 1;
  /* height: 80vh; Ensures full height */
  overflow-y: auto; /* Allows scrolling inside */
}

.div5 {
  grid-column: span 2 / span 2;
  grid-row: span 6 / span 6;
  grid-column-start: 5;
  grid-row-start: 1;
  /* height: 80vh; Ensures full height */
  overflow-y: auto; /* Allows scrolling inside */
}

.splash-container {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 5s ease-in-out, transform 5s ease-in-out;
}

.splash-container.hidden {
  opacity: 0;
  transform: translateY(-50px);
  pointer-events: none;
}
.selectedFilterBtn {
  background-color: #04B7B1 !important;
  border: 0.5px solid gray !important ;
  /* width: 18% !important; */
  padding: 5px 15px 7px 15px !important;
  border-radius: 3px !important;
  margin-top: 2px !important;
  text-transform: capitalize;
}

.unselectedFilterBtn {
  background-color: #ffffff !important;
  border: 0.5px solid gray !important ;
  color: gray !important;
  /* width: 18% !important; */
  padding: 5px 15px 5px 15px !important;
  border-radius: 3px !important;
  margin-top: 2px !important;
  text-transform: capitalize;
}

.hover-row:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.highlighted-row:hover {
  background-color: #04b7b151 !important;
  cursor: pointer;
}

.highlighted-row {
  background-color: #04b7b151 !important;
}

.disabled-row {
  pointer-events: fill; /* Prevent row clicks */
  background-color: #ff00003b !important; /* Semi-transparent red */
  position: relative; /* Needed for the tooltip */
}

.disabled-row::after {
  content: "This row is locked because it is processed"; /* Tooltip text */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center text */
  background-color: rgba(0, 0, 0, 0.85); /* Dark background */
  color: white;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 5px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
}

.disabled-row:hover::after {
  opacity: 1; /* Show text on hover */
}
