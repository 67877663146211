  /* scrollBar css */
  :root {
    --clr-cyan: #03a09b;
    --clr-dark-cyan: #03a09ba3;
  }
  
  /* ----- CHANGE THE SCROLL BAR DESIGN ----- */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 25px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 25px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: var(--clr-cyan);
    border-radius: 30px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: var(--clr-dark-cyan);
  }

  /* -------- SIDEBAR AND TAB VIEW DESIGNS ---------- */
  :where(.css-dev-only-do-not-override-1km3mtt).ant-menu-dark {
    background: #04b7b1;
  }
  
  :where(.css-dev-only-do-not-override-1km3mtt).ant-menu-dark.ant-menu-submenu>.ant-menu {
    font-size: 1vw;
    font-family: "Urbanist";
    color: white;
    background-color: #04b7b1;
  }

  :where(.css-dev-only-do-not-override-1km3mtt).ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):active {
    background-color: #334b49b6;
  }

  :where(.css-dev-only-do-not-override-1km3mtt).ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-submenu-title:active {
    background-color: #334b49b6;
  }

  /* :where(.css-dev-only-do-not-override-1km3mtt).ant-layout .ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
    width: 5dvw;
    margin: auto;
  } */

  :where(.css-dev-only-do-not-override-1km3mtt).ant-menu-submenu-popup .ant-menu-vertical >.ant-menu-item, :where(.css-dev-only-do-not-override-1km3mtt).ant-menu-submenu-popup .ant-menu-vertical >.ant-menu-submenu>.ant-menu-submenu-title {
    height: 35px;
    line-height: 35px;
  }
  
  li.ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-selected {
    background-color: #334b4977;
  }

  .ant-menu-inline>.ant-menu-item, .ant-menu-vertical>.ant-menu-item, .ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title, .ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title {
    height: 4.8dvh;
    line-height: 5.15dvh;
}
  
  /* -------- HEADER -------- */
  :where(.css-dev-only-do-not-override-1km3mtt).ant-layout .ant-layout-header {
    height: auto;
    padding: 6px !important;
    padding-inline: 50px;
    color: rgba(0, 0, 0, 0.88);
    line-height: 64px;
}

  /* ------ MEDIA QUERY FOR TAB --------- */
  @media (max-width: 768px){
      :where(.css-dev-only-do-not-override-1km3mtt).ant-layout .ant-layout-sider {
        background: rgb(4, 183, 177);
        flex: 0 0 8dvw !important;
        max-width: 8dvw !important;
        min-width: 8dvw !important;
        width: 8dvw !important;
      }

      :where(.css-dev-only-do-not-override-1km3mtt).ant-menu-submenu-popup .ant-menu-vertical >.ant-menu-item, :where(.css-dev-only-do-not-override-1km3mtt).ant-menu-submenu-popup .ant-menu-vertical >.ant-menu-submenu>.ant-menu-submenu-title {
        height: 20px;
        line-height: 20px;
      }
  }
